@import '../themes/variables.scss';
@font-face {
  font-family: "MergeOne-Regular";
  src: local("MergeOne-Regular"),
    url("../fonts/MergeOne-Regular.ttf");
}
@font-face {
  font-family: "Manrope-Regular";
  src: local("Manrope-Regular"),
    url("../fonts/Manrope-Regular.ttf");
}
@font-face {
  font-family: "Manrope-Medium";
  src: local("Manrope-Medium"),
    url("../fonts/Manrope-Medium.ttf");
}
@font-face {
  font-family: "Manrope-Bold";
  src: local("Manrope-Bold"),
    url("../fonts/Manrope-Bold.ttf");
}
@font-face {
  font-family: "BebasNeue-Regular";
  src: local("BebasNeue-Regular"),
    url("../fonts/BebasNeue-Regular.ttf");
}

@font-face {
  font-family: "Benne-Regular";
  src: local("Benne-Regular"),
    url("../fonts/Benne-Regular.ttf");
}

@font-face {
  font-family: "Catamaran-Regular";
  src: local("Catamaran-Regular"),
    url("../fonts/Catamaran-Regular.ttf");
}

@font-face {
  font-family: "GreatVibes-Regular";
  src: local("GreatVibes-Regular"),
    url("../fonts/GreatVibes-Regular.ttf");
}

@font-face {
  font-family: "Neuton-Regular";
  src: local("Neuton-Regular"),
    url("../fonts/Neuton-Regular.ttf");
}

@font-face {
  font-family: "ZillaSlab-Regular";
  src: local("ZillaSlab-Regular"),
    url("../fonts/ZillaSlab-Regular.ttf");
}

@font-face {
  font-family: "Raleway-Regular";
  src: local("Raleway-Regular"),
    url("../fonts/Raleway-Regular.ttf");
}

@font-face {
  font-family: "CormorantGaramond-Regular";
  src: local("CormorantGaramond-Regular"),
    url("../fonts/CormorantGaramond-Regular.ttf");
}

@font-face {
  font-family: "DMSans-Regular";
  src: local("DMSans-Regular"),
    url("../fonts/DMSans-Regular.ttf");
}

html{
  height: 100%;
  width:100%
}
body {
  font-family: 'Manrope', sans-serif;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.primary-btn {
  width: 100%;
  height: 45px;
  background-color: #6E2CDC;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  border-radius: 8px;
  &:hover {
    background-color: #6c32ca;
  }
}

.page-published-url {
  height: 44px;
  border: 1px solid #fff;
  color: white;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 20px;
  border-radius: 6px;
  line-height: 44px;
  padding: 0 7px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

