$xs: 300px;
$sm: 500px;
$md: 800px;
$lg: 1300px;
$xl: 1500px;


@mixin xs {
  @media (max-width: #{$xs}) {
    @content;
  }
}

@mixin sm {
  @media (max-width: #{$sm}) {
    @content;
  }
}

  
@mixin md {
  @media (max-width: #{$md}) {
    @content;
  }
}

  
@mixin lg {
  @media (max-width: #{$xl}) {
    @content;
  }
}

  
@mixin xl {
  @media (min-width: #{$xl - 1px}) {
    @content;
  }
}

.MuiInput-root {
  height: 42px
}
