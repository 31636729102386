@import '../../themes/mixins.scss';

.darkInputField {
  .MuiFilledInput-root {
    border: 1px solid #d1d1d1 !important;
    background-color: #343434 !important;
    color: white !important;
    height: 52px !important;
    overflow: hidden;
    @include md {
      font-size: 14px;
    }
  }
}
.inputField {
  .MuiFilledInput-root {
    overflow: hidden;
  }
}